var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "login-bg top" }),
    _c(
      "div",
      { staticClass: "login-display" },
      [
        _c("div", { staticClass: "login-logo" }, [
          _vm._m(0),
          _vm._m(1),
          _c(
            "div",
            { staticStyle: { position: "absolute", top: "0", right: "0" } },
            [_c("test-tag")],
            1
          )
        ]),
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "login-form",
            attrs: {
              autoComplete: "on",
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { staticClass: "login-input", attrs: { prop: "mobile" } },
              [
                _c(
                  "span",
                  { staticClass: "login-svg-left" },
                  [_c("svg-icon", { attrs: { "icon-class": "phone" } })],
                  1
                ),
                _c("el-input", {
                  attrs: {
                    name: "mobile",
                    type: "text",
                    autoComplete: "on",
                    placeholder: "请输入手机号码或账号",
                    clearable: ""
                  },
                  model: {
                    value: _vm.loginForm.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "mobile", $$v)
                    },
                    expression: "loginForm.mobile"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "login-input", attrs: { prop: "password" } },
              [
                _c(
                  "span",
                  { staticClass: "login-svg-left" },
                  [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                  1
                ),
                _c("el-input", {
                  attrs: {
                    name: "password",
                    type: _vm.pwdType,
                    autoComplete: "on",
                    placeholder: "请输入登录密码"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    }
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginForm.password,
                        expression: "loginForm.password"
                      }
                    ],
                    staticClass: "login-svg-right",
                    on: { click: _vm.showPwd }
                  },
                  [
                    _vm.pwdType === "password"
                      ? _c("svg-icon", { attrs: { "icon-class": "eye" } })
                      : _c("svg-icon", { attrs: { "icon-class": "eye-open" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "login-btn" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    attrs: { size: "medium", loading: _vm.loading },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      }
                    }
                  },
                  [_vm._v("\n          登录\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", { attrs: { src: require("../../assets/images/logo.png") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-title" }, [
      _c("span", [_vm._v("欢迎登录后台管理系统")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }