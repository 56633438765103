//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { isvalidUsername } from '@/utils/validate'
import TestTag from '@/components/TestTag';
export default {
  name: 'login',
  components: {
    TestTag: TestTag
  },
  data: function data() {
    return {
      loginForm: {
        mobile: '',
        password: ''
      },
      loginRules: {
        mobile: [{
          required: true,
          message: '账号必须'
        }],
        password: [{
          required: true,
          message: '密码必须'
        }]
      },
      loading: false,
      pwdType: 'password'
    };
  },
  methods: {
    showPwd: function showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = '';
      } else {
        this.pwdType = 'password';
      }
    },
    handleLogin: function handleLogin() {
      var _this = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;

          _this.$store.dispatch('LoginByUsername', _this.loginForm).then(function () {
            _this.loading = false;

            _this.$router.push({
              path: '/welcome'
            });
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};